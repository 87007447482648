var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"scrollable":"","min-width":"400px","max-width":"1000px"},on:{"click:outside":_vm.dialogTF},model:{value:(this.$store.state.message_list_dialog),callback:function ($$v) {_vm.$set(this.$store.state, "message_list_dialog", $$v)},expression:"this.$store.state.message_list_dialog"}},[_c('v-card',[_c('v-card-title',{staticStyle:{"background":"#fae396"}},[_vm._v(" Message List ")]),_c('v-divider'),_c('v-card',{staticClass:"fullheight overflow-auto pa-2"},_vm._l((_vm.message_data),function(message){return _c('v-card',{key:message.id,staticClass:"pa-2",attrs:{"min-height":"20px","width":"100%","outlined":""},on:{"click":function($event){return _vm.message_info_add(
            message.contents,
            message.date,
            message.from,
            message.media,
            message.subject,
            message.to,
            message.user
          )}}},[_c('div',{staticClass:"message_cards"},[_c('p',{staticClass:"ma-0 service_font"},[_vm._v(" "+_vm._s(message.media)+"："+_vm._s(_vm.getStringFromDate(message.date.toDate(), message.media))+" ")]),_c('p',{staticClass:"ma-0 from_font"},[_vm._v(" "+_vm._s(_vm.message_userCheck(message.from, message.user))+" ")])]),_c('p',{staticClass:"ma-0 subject_font font-weight-bold"},[_vm._v(" "+_vm._s(message.subject)+" ")])])}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }