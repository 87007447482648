import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCZs5AdHCUa7sTcBLFPFe6xyaWBaR9kiC4",
  authDomain: "blitzhr-test.firebaseapp.com",
  projectId: "blitzhr-test",
  storageBucket: "blitzhr-test.appspot.com",
  messagingSenderId: "750533303837",
  appId: "1:750533303837:web:4179c2cbc34bc137c530f4",
  measurementId: "G-JDQN9N13G7",
};

const firebaseApp = initializeApp(firebaseConfig);
const db = getFirestore();

export { firebaseApp, db };
