<template>
  <v-dialog
    v-model="this.$store.state.message_dialog"
    scrollable
    min-width="400px"
    max-width="800px"
    @click:outside="dialogTF"
  >
    <v-card>
      <v-card-title class="pa-2"> {{ message_info.subject }}</v-card-title>
      <p class="px-2 ma-0 from_font">
        {{ message_info.media }}：{{
          getStringFromDate(message_info.date, message_info.media)
        }}
      </p>
      <p class="px-2 ma-0 from_font">
        {{ "From：" + message_userCheck(message_info.from, message_info.user) }}
      </p>
      <p class="px-2 pb-2 ma-0 from_font">
        {{
          "to：" +
          message_userCheck_to(
            message_info.from,
            message_info.to,
            message_info.user
          )
        }}
      </p>
      <v-divider></v-divider>
      <v-card-text class="pa-4" style="white-space: pre-line; color: #23282b">
        {{ message_info.contents }}
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<style>
.from_font {
  font-size: 0.85rem;
  color: #37474f;
}
.service_font {
  font-size: 0.85rem;
  color: #6d7070;
}
</style>

<script>
export default {
  data: () => ({}),

  computed: {
    message_info: function () {
      return this.$store.state.message_info;
    },
  },

  methods: {
    dialogTF() {
      this.$store.commit("message_dialog");
    },
    //メッセージのFromチェック
    message_userCheck(from, user) {
      if (from == "応募者") {
        return user + "様";
      } else {
        return from;
      }
    },
    //メッセージのtoチェック
    message_userCheck_to(from, to, user) {
      if (to == undefined) {
        if (from != "応募者") {
          return user + "様";
        } else {
          return "当社";
        }
      } else return to;
    },
    //タイムスタンプを日付になおす
    getStringFromDate(timestamp, media) {
      if (this.$store.state.message_dialog) {
        var date = timestamp.toDate();
        var year_str = date.getFullYear();
        //月だけ+1すること
        var month_str = ("0" + (1 + date.getMonth())).slice(-2);
        var day_str = ("0" + date.getDate()).slice(-2);
        var hour_str = ("0" + date.getHours()).slice(-2);
        var minute_str = ("0" + date.getMinutes()).slice(-2);
        var second_str = ("0" + date.getSeconds()).slice(-2);

        var format_str = "YYYY/MM/DD hh:mm";
        if (media == "WANTEDLY") {
          format_str = "YYYY/MM/DD";
        }
        format_str = format_str.replace(/YYYY/g, year_str);
        format_str = format_str.replace(/MM/g, month_str);
        format_str = format_str.replace(/DD/g, day_str);
        format_str = format_str.replace(/hh/g, hour_str);
        format_str = format_str.replace(/mm/g, minute_str);
        format_str = format_str.replace(/ss/g, second_str);
        return format_str;
      }
    },
  },
};
</script>

<style></style>
