<template>
  <v-row class="pa-0 ma-0 fullheight">
    <v-col
      class="pa-2"
      v-for="(status, index) in status_tf_array()"
      :key="index"
    >
      <v-card
        class="overflow-auto"
        min-width="250px"
        height="650px"
        color="#f2f2f2"
      >
        <p class="title_Status_font font-weight-bold">
          {{ status }}
        </p>
        <p class="title_Status_font font-weight-bold">
          {{ aryCheck(users, status).length }}人
        </p>
        <v-row class="pa-0 ma-0">
          <v-col
            class="pa-0"
            v-for="user in aryCheck(users, status)"
            :key="user.id"
          >
            <v-card
              @click.native="data_info_check(user.id)"
              min-width="182px"
              max-width="340px"
              max-height="300px"
              outlined
              elevation="1"
              class="user_cards"
              retain-focus-on-click
            >
              <v-expand-transition>
                <v-card
                  v-if="data_info == user.id"
                  class="
                    d-flex
                    transition-fast-in-fast-out
                    blue-grey
                    darken-1
                    data_info_cards
                    white--text
                  "
                  style="height: 100%"
                >
                  <p class="data_info_text">
                    応募日：{{
                      user.Start ? getStringFromDate(user.Start) : ""
                    }}
                  </p>
                  <p class="data_info_text">
                    一次面接：{{
                      user.status1 ? getStringFromDate(user.status1) : ""
                    }}
                  </p>
                  <p class="data_info_text">
                    二次面接：{{
                      user.status2 ? getStringFromDate(user.status2) : ""
                    }}
                  </p>
                  <p class="data_info_text">
                    三次面接：{{
                      user.status3 ? getStringFromDate(user.status3) : ""
                    }}
                  </p>
                </v-card>
              </v-expand-transition>

              <p
                class="title_font font-weight-bold"
                @click="user_info_add(user), get_user_message()"
              >
                {{ user.name }}
              </p>
              <v-btn
                height="1.2rem"
                text
                class="pa-0"
                @click="data_info_check(user.id)"
              >
                <a
                  :href="serviceList[user.Route]"
                  target="_blank"
                  rel="noopener noreferrer"
                  class="Route_font font-weight-bold"
                >
                  {{ user.Route }}
                </a>
              </v-btn>
              <p class="dp_oc_font font-weight-bold">
                {{ user.Depart }}
              </p>
              <p class="dp_oc_font font-weight-bold">
                {{ user.Occupation }}
              </p>
              <div style="display: flex; align-items: center">
                <p class="update_font font-weight-bold">
                  更新日：{{ getStringFromDate(user.Updated) }}
                </p>
                <v-btn
                  @click="data_info_check(user.id)"
                  :href="user.Hearing"
                  target="_blank"
                  rel="noopener noreferrer"
                  icon
                  :color="user.Hearing ? 'green' : 'grey'"
                  x-small
                >
                  <v-icon small>mdi-file-document</v-icon>
                </v-btn>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
    <User_message_Dialogs />
    <message_Dialogs />
  </v-row>
</template>

<style scoped lang="css">
.data_info_cards {
  top: 100%;
  left: 0;
  position: absolute;
  width: 100%;
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.data_info_text {
  font-size: 1rem;
  padding-inline: 0.5rem;
  margin: 0rem;
}
.fullheight {
  height: 100%;
  background: #faeec8;
}
.user_cards {
  margin: 0.1rem 0.3rem;
  padding: 0.35rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.title_Status_font {
  font-size: 1rem;
  color: #37474f;
  padding-inline: 0.5rem;
  margin: 0rem;
}
.title_font {
  cursor: pointer;
  font-size: 1.1rem;
  color: #37474f;
  padding-inline: 0.5rem;
  margin: 0rem;
}
.Route_font {
  font-size: 1rem;
  color: #37474f;
  padding-inline: 0.5rem;
  margin: 0rem;
  text-decoration: none;
}
.dp_oc_font {
  font-size: 0.9rem;
  height: 1.15rem;
  color: #6d7070;
  padding-inline: 0.5rem;
  margin: 0rem;
}
.update_font {
  font-size: 0.8rem;
  color: #e65252;
  padding-inline: 0.5rem;
  margin: 0;
}
</style>

<script>
import { db } from "../firebase/firebase";
import { collection, query, where, getDocs } from "firebase/firestore";
import User_message_Dialogs from "./User_message_Dialogs.vue";
import message_Dialogs from "./message_Dialogs.vue";
export default {
  components: { User_message_Dialogs, message_Dialogs },
  props: {
    nameFilterValue_compo: String,
    DepartmetsFilterValue_compo: String,
    OccupationsFilterValue_compo: String,
    RoutesFilterValue_compo: String,
    DateFilterValue_compo: String,
    SortListValue_compo: String,
  },
  data: () => ({
    data_info: -1,
    maildialog: false,
    serviceList: {
      WANTEDLY:
        "https://www.wantedly.com/enterprise/tickets?state=active&sort_type=number_reverse&page=1",
      キャリトレ:
        "https://cr.careertrek.com/progress-management/?recruiterId=28295",
      ミイダス: "https://corp.miidas.jp/auth/login",
      ビズリーチ: "https://cr-support.jp/progress/",
      GREEN: "https://www.green-japan.com/client/managements/users",
    },
    status_list: ["書類選考", "一次選考", "二次選考", "最終選考"],
    user_info: {},
  }),

  computed: {
    users: function () {
      return this.$store.state.user_data;
    },
  },

  methods: {
    //カード用配列フィルター
    aryCheck(array, status) {
      if (this.DateFilterValue_compo != null) {
        var date = new Date(this.DateFilterValue_compo);
        date.setHours(date.getHours() - 9);
      }
      var new_array = array.filter((value) => {
        if (
          (status == "All" || value.Status == status) &&
          (this.nameFilterValue_compo == "" ||
            value.name.includes(this.nameFilterValue_compo)) &&
          (this.DepartmetsFilterValue_compo == null ||
            value.Depart == this.DepartmetsFilterValue_compo) &&
          (this.OccupationsFilterValue_compo == null ||
            value.Occupation == this.OccupationsFilterValue_compo) &&
          (this.RoutesFilterValue_compo == null ||
            value.Route == this.RoutesFilterValue_compo) &&
          (this.DateFilterValue_compo == null ||
            value.Start.seconds >= date.getTime() / 1000)
        ) {
          return true;
        }
      });
      if (this.SortListValue_compo == "降順") {
        new_array.sort((a, b) => b.Updated - a.Updated);
      } else {
        new_array.sort((a, b) => a.Updated - b.Updated);
      }
      return new_array;
    },
    //ステータスごとの表示情報をstoreに送信
    status_tf_array() {
      var status_list = [];
      this.$store.state.User_Status1 ? status_list.push("書類選考") : "";
      this.$store.state.User_Status2 ? status_list.push("一次選考") : "";
      this.$store.state.User_Status3 ? status_list.push("二次選考") : "";
      this.$store.state.User_Status4 ? status_list.push("最終選考") : "";
      return status_list;
    },
    //カードクリック時に下からひょっと出るやつをIdで指定
    data_info_check(id) {
      if (this.data_info == id || this.$store.state.user_messagelist_dialog) {
        this.data_info = -1;
      } else {
        this.data_info = id;
      }
    },
    //storeに選択したユーザーの情報を入れる
    user_info_add(user) {
      this.user_info = user;
      this.$store.commit("user_info", this.user_info);
      // console.log(this.$store.state.user_info);
    },
    //ユーザーのメッセージデータを受信
    async get_user_message() {
      var user_message_list = [];
      this.$store.commit("user_message_list", user_message_list);
      // console.log(this.user_info.Email);
      var q = await query(
        collection(db, "messages"),
        where("from", "==", this.user_info.Email)
      );
      var querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        user_message_list.push(doc.data());
      });
      q = await query(
        collection(db, "messages"),
        where("to", "==", this.user_info.Email)
      );
      querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        user_message_list.push(doc.data());
      });
      q = await query(
        collection(db, "messages"),
        where("user", "==", this.user_info.name),
        where("media", "==", this.user_info.Route)
      );
      querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        user_message_list.push(doc.data());
      });
      user_message_list.sort((a, b) => b.date - a.date);
      // console.log(user_message_list);
      this.$store.commit("user_message_list", user_message_list);
    },
    //タイムスタンプを日付になおす
    getStringFromDate(timestamp) {
      var date = timestamp.toDate();
      var year_str = date.getFullYear();
      //月だけ+1すること
      var month_str = ("0" + (1 + date.getMonth())).slice(-2);
      var day_str = ("0" + date.getDate()).slice(-2);
      var hour_str = ("0" + date.getHours()).slice(-2);
      var minute_str = ("0" + date.getMinutes()).slice(-2);
      var second_str = ("0" + date.getSeconds()).slice(-2);

      var format_str = "MM/DD";
      format_str = format_str.replace(/YYYY/g, year_str);
      format_str = format_str.replace(/MM/g, month_str);
      format_str = format_str.replace(/DD/g, day_str);
      format_str = format_str.replace(/hh/g, hour_str);
      format_str = format_str.replace(/mm/g, minute_str);
      format_str = format_str.replace(/ss/g, second_str);
      return format_str;
    },
  },
};
</script>

<style></style>
