<template>
  <v-app>
    <v-app-bar app color="blue-grey lighten-5" dark>
      <div class="d-flex align-center">
        <v-img
          alt="Blitz Logo"
          class="shrink"
          contain
          src="@/assets/横ロゴ.png"
          transition="scale-transition"
          width="120"
        />
        <v-img
          alt="Blitz Logo"
          class="shrink"
          contain
          src="@/assets/ロゴ文字.png"
          transition="scale-transition"
          width="210"
        />
      </div>
      <v-spacer></v-spacer>
      <v-btn color="#fabe00" dark depressed @click="message_list_dialog">
        <v-icon>mdi-email </v-icon>
      </v-btn>
      <v-btn href="" target="_blank" color="black" text @click="sinout">
        <span class="mr-2">LOGOUT</span>
        <v-icon>mdi-open-in-new</v-icon>
      </v-btn>
    </v-app-bar>
    <v-main>
      <UserTable />
      <message_list_Dialogs />
    </v-main>
  </v-app>
</template>

<style lang="scss">
//----------------------------------------------------------------
@import url(https://fonts.googleapis.com/earlyaccess/notosansjp.css);
* :not(.v-icon) {
  font-family: "Hiragino Kaku Gothic Pro", "hiragino kaku gothic pro",
    "ヒラギノ角ゴシック", "ヒラギノ角ゴシック W0", "system-ui", "Noto Sans JP",
    "メイリオ", sans-serif !important;
}
* {
  text-transform: none !important;
}
.v-application--wrap {
  min-height: 0vh !important;
}
body {
  transform-origin: top left;
}
</style>

<script>
var change_scale = {
  container: 1920,
  percent: 1,
  function: function () {
    if (change_scale.percent === window.devicePixelRatio) {
      var scale = screen.width;
      if (scale >= 1024) {
        scale = scale / change_scale.container;
        var scale1 = scale * 100 + "%";
        document.body.style.zoom = scale1;
        document.body.style.width =
          Math.round(window.innerWidth / scale) + "px";
      }
    } else {
      change_scale.percent = window.devicePixelRatio;
    }
  },
};
window.addEventListener("resize", function () {
  change_scale.function();
});
window.addEventListener("load", function () {
  change_scale.function();
  // console.log("読み込み完了");
});
change_scale.function();
// console.log("読み込み完了２");

import UserTable from "./components/UserTable";
import message_list_Dialogs from "./components/message_list_Dialogs";
import { firebaseApp, db } from "./firebase/firebase";
import {
  getAuth,
  onAuthStateChanged,
  GoogleAuthProvider,
  signInWithRedirect,
  signOut,
} from "firebase/auth";
import {
  collection,
  query,
  onSnapshot,
  where,
  orderBy,
  limit,
} from "firebase/firestore";
//----------------------------------------------------------------
export default {
  name: "App",
  //----------------------------------------------------------------

  components: {
    UserTable,
    message_list_Dialogs,
  },

  data: () => ({
    //
  }),

  created() {
    //ログイン処理
    const provider = new GoogleAuthProvider();
    const auth = getAuth(firebaseApp);
    onAuthStateChanged(auth, (user) => {
      if (user) {
        console.log("ログイン成功");
        //-----------------------------------------------------
        const q = query(
          collection(db, "messages"),
          orderBy("date", "desc"),
          limit(50)
        );
        onSnapshot(q, (querySnapshot) => {
          const message = [];
          querySnapshot.forEach((doc) => {
            message.push(doc.data());
          });
          this.$store.commit("message_data", message);
          // console.log(this.$store.state.message_data);
        });
        //-----------------------------------------------------
        const qu = query(
          collection(db, "users"),
          where("Progress", "==", "選考中")
        );
        onSnapshot(qu, (querySnapshot) => {
          const user = [];
          querySnapshot.forEach((doc) => {
            const data = doc.data();
            data.id = doc.id;
            user.push(data);
          });
          this.$store.commit("user_data", user);
          // console.log(this.$store.state.user_data);
        });
        //-----------------------------------------------------
      } else {
        console.log("ログイン失敗");
        signInWithRedirect(auth, provider);
      }
    });
  },

  computed: {},

  methods: {
    //サインアウト
    sinout() {
      const auth = getAuth();
      signOut(auth)
        .then(() => {
          // Sign-out successful.
        })
        .catch(() => {
          // An error happened.
        });
    },
    //メッセージリストのダイアログ表示
    message_list_dialog() {
      // console.log(this.$store.state.message_list_dialog);
      this.$store.commit("message_list_dialog");
    },
  },
};
</script>
