import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    message_data: [], //初期取得メッセージリストのデータ
    user_data: [], //初期取得ユーザーリストのデータ
    //ステータスTorFチェック-----
    User_Status1: true,
    User_Status2: true,
    User_Status3: true,
    User_Status4: true,
    //ダイアログ用-------
    user_info: {}, //選択ユーザー情報
    user_message_list: [],
    message_info: {}, //選択メッセージ情報
    user_messagelist_dialog: false,
    message_dialog: false,
    message_list_dialog: false,
    //-------------------------
  },
  mutations: {
    //ステータスTorFチェック-----
    User_Status1: function (state) {
      state.User_Status1 = !state.User_Status1;
      console.log(state.User_Status1);
    },
    User_Status2: function (state) {
      state.User_Status2 = !state.User_Status2;
    },
    User_Status3: function (state) {
      state.User_Status3 = !state.User_Status3;
    },
    User_Status4: function (state) {
      state.User_Status4 = !state.User_Status4;
    },

    //選択ユーザーのメッセージリストダイアログ表示
    message_list_dialog: function (state) {
      state.message_list_dialog = !state.message_list_dialog;
    },
    //選択ユーザーデータの取得
    user_info: function (state, user_data) {
      state.user_info = user_data;
      state.user_messagelist_dialog = true;
    },
    //選択ユーザーのメッセージリストダイアログ表示
    user_messagelist_dialog: function (state) {
      state.user_messagelist_dialog = false;
    },
    //選択ユーザーのメッセージリストの取得
    user_message_list: function (state, user_message_list) {
      state.user_message_list = user_message_list;
    },

    //選択メッセージデータの取得
    message_info: function (state, message_data) {
      state.message_info = message_data;
      state.message_dialog = true;
    },
    //選択メッセージのダイアログ表示
    message_dialog: function (state) {
      state.message_dialog = false;
    },

    //データベースからメッセージの取得
    message_data: function (state, message_object) {
      state.message_data = message_object;
    },
    //データベースからメッセージの取得
    user_data: function (state, user_data) {
      state.user_data = user_data;
    },
  },
  actions: {},
  modules: {},
});
