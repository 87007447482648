<template>
  <v-container fluid>
    <v-row class="px-7">
      <v-col cols="12">
        <v-card outlined class="pt-3">
          <v-row>
            <v-col md="auto" cols="6" class="mx-auto">
              <v-row class="px-6 py-1">
                <v-text-field
                  v-model="nameFilterValue"
                  type="text"
                  label="氏名"
                >
                </v-text-field>
              </v-row>
            </v-col>
            <v-col md="auto" cols="6" class="px-6 py-1 mx-auto">
              <v-select
                :items="DepartmetsList"
                v-model="DepartmetsFilterValue"
                label="事業部"
              ></v-select>
            </v-col>
            <v-col md="auto" cols="6" class="px-6 py-1 mx-auto">
              <v-select
                :items="OccupationsList"
                v-model="OccupationsFilterValue"
                label="職種"
              ></v-select>
            </v-col>
            <v-col md="auto" cols="6" class="px-6 py-1 mx-auto">
              <v-select
                :items="RoutsList"
                v-model="RoutesFilterValue"
                label="経路"
              ></v-select>
            </v-col>
            <v-col md="auto" cols="6" class="px-6 py-1 mx-auto">
              <v-menu
                ref="menu"
                v-model="date_Filter_menu"
                :close-on-content-click="false"
                :return-value.sync="DateFilterValue"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="DateFilterValue"
                    label="応募日"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="DateFilterValue"
                  no-title
                  scrollable
                  locale="ja"
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="DateFilterValue = null">
                    Reset
                  </v-btn>
                  <v-btn text color="primary" @click="date_Filter_menu = false">
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.menu.save(DateFilterValue)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col md="auto" cols="6" class="mx-auto px-6 py-1">
              <v-select
                :items="SortList"
                v-model="SortListValue"
                label="更新日並べ替え"
              ></v-select>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="px-7 mt-0">
      <v-col>
        <v-btn block color="#fabe00" @click="UserTsble_TF = !UserTsble_TF">
          User Table
        </v-btn>
      </v-col>
      <v-col>
        <v-btn block color="#fabe00" @click="MailList_TF = !MailList_TF">
          Message List
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="px-7 mt-0">
      <v-col>
        <v-scroll-y-transition>
          <v-row v-show="UserTsble_TF">
            <v-col class="mb-3">
              <v-data-table
                dense
                :headers="headers"
                :items="$store.state.user_data"
                item-key="id"
                class="elevation-1 pa-6"
              >
                <template v-slot:[`item.Start`]="{ item }">
                  {{ getStringFromDate(item.Start) }}
                </template>
                <template v-slot:[`item.Updated`]="{ item }">
                  {{ getStringFromDate(item.Updated) }}
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-scroll-y-transition>
        <v-row class="pa-0 ma-0">
          <v-col class="pa-0 ma-0">
            <v-btn
              block
              depressed
              :color="$store.state.User_Status1 ? '#fae396' : '#dddee1'"
              @click="User_Status1"
              class="font-weight-bold"
            >
              書類選考
            </v-btn>
          </v-col>
          <v-col class="pa-0 ma-0">
            <v-btn
              block
              depressed
              :color="$store.state.User_Status2 ? '#fad764' : '#dddee1'"
              @click="User_Status2"
              class="font-weight-bold"
            >
              一次選考
            </v-btn>
          </v-col>
          <v-col class="pa-0 ma-0">
            <v-btn
              block
              depressed
              :color="$store.state.User_Status3 ? '#fad14b' : '#dddee1'"
              @click="User_Status3"
              class="font-weight-bold"
            >
              二次選考
            </v-btn>
          </v-col>
          <v-col class="pa-0 ma-0">
            <v-btn
              block
              depressed
              :color="$store.state.User_Status4 ? '#fabe00' : '#dddee1'"
              @click="User_Status4"
              class="font-weight-bold"
            >
              最終選考
            </v-btn>
          </v-col>
        </v-row>
        <v-row class="mt-0 widthauto">
          <v-col class="pt-0 minwidthi">
            <v-card height="670px">
              <!-- ユーザーリストコンポーネント -->
              <UserList
                :nameFilterValue_compo="nameFilterValue"
                :DepartmetsFilterValue_compo="DepartmetsFilterValue"
                :OccupationsFilterValue_compo="OccupationsFilterValue"
                :RoutesFilterValue_compo="RoutesFilterValue"
                :DateFilterValue_compo="DateFilterValue"
                :SortListValue_compo="SortListValue"
              />
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <v-scroll-y-transition>
        <v-col v-show="MailList_TF">
          <!-- メールリストコンポーネント -->
          <MailList />
        </v-col>
      </v-scroll-y-transition>
    </v-row>
  </v-container>
</template>

<style scoped lang="css">
.fullheight {
  height: 100%;
  background: #faeec8;
}
.widthauto {
  overflow-x: scroll;
}
.minwidthi {
  min-width: 1450px;
}
</style>

<script>
import UserList from "./UserList.vue";
import MailList from "./MailList.vue";
export default {
  name: "Table",
  components: {
    UserList,
    MailList,
  },
  data: () => ({
    UserTsble_TF: false,
    MailList_TF: true,
    date_Filter_menu: false,
    DepartmetsList: [
      { text: "すべて", value: null },
      { text: "WEBマーケティング", value: "WEBマーケティング" },
      { text: "D2C", value: "D2C" },
      { text: "bitcastle", value: "bitcastle" },
      { text: "管理本部", value: "管理本部" },
      { text: "WEBコンサルティング", value: "WEBコンサルティング" },
    ],
    OccupationsList: [
      { text: "すべて", value: null },
      { text: "ライター", value: "ライター" },
      { text: "デザイナー", value: "デザイナー" },
      { text: "企画開発", value: "企画開発" },
      { text: "商品開発", value: "商品開発" },
      { text: "広告運用課長", value: "広告運用課長" },
      { text: "CS", value: "CS" },
      { text: "PM", value: "PM" },
      { text: "COO", value: "COO" },
      { text: "海外マーケター", value: "海外マーケター" },
      { text: "SEOマーケター", value: "SEOマーケター" },
      { text: "インサイドセールス", value: "インサイドセールス" },
      { text: "動画編集", value: "動画編集" },
      { text: "中途採用担当", value: "中途採用担当" },
      { text: "エンジニア", value: "エンジニア" },
      { text: "営業", value: "営業" },
    ],
    RoutsList: [
      { text: "すべて", value: null },
      { text: "GREEN", value: "GREEN" },
      { text: "リワーカー", value: "リワーカー" },
      { text: "LiBzCAREER", value: "LiBzCAREER" },
      { text: "WANTEDLY", value: "WANTEDLY" },
      { text: "ミイダス", value: "ミイダス" },
      { text: "ビズリーチ", value: "ビズリーチ" },
      { text: "Geekly", value: "Geekly" },
    ],
    SortList: [
      { text: "降順", value: "降順" },
      { text: "昇順", value: "昇順" },
    ],
    // Filter models.
    nameFilterValue: "",
    OccupationsFilterValue: null,
    DepartmetsFilterValue: null,
    RoutesFilterValue: null,
    DateFilterValue: null,
    SortListValue: "降順",
    // sumpleData
  }),
  computed: {
    headers() {
      return [
        {
          text: "氏名",
          sortable: false,
          value: "name",
          filter: this.nameFilter,
        },
        {
          text: "事業部",
          value: "Depart",
          filter: this.DepartmetsFilter,
        },
        {
          text: "職種",
          value: "Occupation",
          filter: this.OccupationsFilter,
        },
        {
          text: "経路",
          value: "Route",
          filter: this.RoutesFilter,
        },
        { text: "応募方法", value: "Method" },
        { text: "ステータス", value: "Status" },
        { text: "応募日", value: "Start", filter: this.DateFilter },
        { text: "最終更新", value: "Updated" },
      ];
    },
  },
  methods: {
    //ステータスTorFチェック-----
    User_Status1: function () {
      this.$store.commit("User_Status1");
    },
    User_Status2: function () {
      this.$store.commit("User_Status2");
    },
    User_Status3: function () {
      this.$store.commit("User_Status3");
    },
    User_Status4: function () {
      this.$store.commit("User_Status4");
    },
    //テーブル用フィルター
    nameFilter(value) {
      if (!this.nameFilterValue) {
        return true;
      }
      return value.toLowerCase().includes(this.nameFilterValue.toLowerCase());
    },
    DepartmetsFilter(value) {
      if (!this.DepartmetsFilterValue) {
        return true;
      }
      return value === this.DepartmetsFilterValue;
    },
    OccupationsFilter(value) {
      if (!this.OccupationsFilterValue) {
        return true;
      }
      return value === this.OccupationsFilterValue;
    },
    RoutesFilter(value) {
      if (!this.RoutesFilterValue) {
        return true;
      }
      return value === this.RoutesFilterValue;
    },
    DateFilter(value) {
      if (!this.DateFilterValue) {
        return true;
      }
      var date = new Date(this.DateFilterValue);
      date.setHours(date.getHours() - 9);
      return value.seconds >= date.getTime() / 1000;
    },
    /* OccupationsFilter(value) {
        if(!this.OccupationsFilterValue) {
          return true
        }
        return value === parseInt(this.OccupationsFilterValue)
      } */
    //タイムスタンプを日付になおす
    getStringFromDate(timestamp) {
      var date = timestamp.toDate();
      var year_str = date.getFullYear();
      //月だけ+1すること
      var month_str = 1 + date.getMonth();
      var day_str = date.getDate();
      var hour_str = date.getHours();
      var minute_str = date.getMinutes();
      var second_str = date.getSeconds();

      var format_str = "YYYY/MM/DD";
      format_str = format_str.replace(/YYYY/g, year_str);
      format_str = format_str.replace(/MM/g, month_str);
      format_str = format_str.replace(/DD/g, day_str);
      format_str = format_str.replace(/hh/g, hour_str);
      format_str = format_str.replace(/mm/g, minute_str);
      format_str = format_str.replace(/ss/g, second_str);
      return format_str;
    },
  },
};
</script>
