<template>
  <v-card class="fullheight overflow-auto pa-2">
    <div style="max-height: 700px">
      <v-card
        v-for="message in message_data"
        :key="message.id"
        min-height="20px"
        width="100%"
        outlined
        class="pa-2"
        @click="
          message_info_add(
            message.contents,
            message.date,
            message.from,
            message.media,
            message.subject,
            message.to,
            message.user
          )
        "
      >
        <div class="message_cards">
          <p class="ma-0 service_font">
            {{ message.media }}：{{
              getStringFromDate(message.date.toDate(), message.media)
            }}
          </p>
          <p class="ma-0 from_font">
            {{ message_userCheck(message.from, message.user) }}
          </p>
        </div>
        <p class="ma-0 subject_font font-weight-bold">
          {{ message.subject }}
        </p>
      </v-card>
    </div>
  </v-card>
</template>

<style>
.message_cards {
  display: flex;
  flex-flow: column;
}
.from_font {
  font-size: 0.85rem;
  color: #37474f;
}
.subject_font {
  font-size: 0.95rem;
  color: #37474f;
}
.service_font {
  font-size: 0.85rem;
  color: #6d7070;
}
</style>

<script>
export default {
  data: () => ({
    message_info: {},
  }),
  computed: {
    //storeからメッセージの受け取り
    message_data: function () {
      return this.$store.state.message_data;
    },
  },
  methods: {
    //メッセージのFromチェック
    message_userCheck(from, user) {
      if (from == "当社" || from == "応募者") {
        return "相手：" + user + "様";
      } else {
        return "From：" + from;
      }
    },
    //選択メッセージをstoreに送信
    message_info_add(contents, date, from, media, subject, to, user) {
      this.message_info = {
        contents: contents,
        date: date,
        from: from,
        media: media,
        subject: subject,
        to: to,
        user: user,
      };
      this.$store.commit("message_info", this.message_info);
      // console.log(this.$store.state.message_info);
    },
    //タイムスタンプを日付になおす
    getStringFromDate(date, media) {
      var year_str = date.getFullYear();
      //月だけ+1すること
      var month_str = ("0" + (1 + date.getMonth())).slice(-2);
      var day_str = ("0" + date.getDate()).slice(-2);
      var hour_str = ("0" + date.getHours()).slice(-2);
      var minute_str = ("0" + date.getMinutes()).slice(-2);
      var second_str = ("0" + date.getSeconds()).slice(-2);

      var format_str = "YYYY/MM/DD hh:mm";
      if (media == "WANTEDLY") {
        format_str = "YYYY/MM/DD";
      }
      format_str = format_str.replace(/YYYY/g, year_str);
      format_str = format_str.replace(/MM/g, month_str);
      format_str = format_str.replace(/DD/g, day_str);
      format_str = format_str.replace(/hh/g, hour_str);
      format_str = format_str.replace(/mm/g, minute_str);
      format_str = format_str.replace(/ss/g, second_str);

      return format_str;
    },
  },
};
</script>
